import { IS_DEV, IS_EPHEMERAL, IS_DEBUG } from '@app/globals/constants';
import { IndexedDB } from 'lib/idb';
import {
 onCLS,
 onFID,
 onFCP,
 onLCP,
 onTTFB,
 onINP,
 CLSMetric,
 FCPMetric,
 FIDMetric,
 INPMetric,
 LCPMetric,
 TTFBMetric,
 ReportCallback,
} from 'web-vitals';

type VitalMetric = CLSMetric | INPMetric | FIDMetric | FCPMetric | LCPMetric | TTFBMetric;

const handleReport: ReportCallback = (metric: VitalMetric) => {
 console.log(`Perf Metric: ${metric.name}, rating: ${metric.rating}, value: ${metric.value}`);
};


const shouldReport = (IS_DEV || IS_EPHEMERAL || IS_DEBUG) && handleReport;

const reportWebVitals = () => {
 if (shouldReport) {
  onCLS(shouldReport);
  onINP(shouldReport);
  onFID(shouldReport);
  onFCP(shouldReport);
  onLCP(shouldReport);
  onTTFB(shouldReport);
 }
};

export interface VitalsReport {
 name: string;
}

export default reportWebVitals;
