import { useCallback, useEffect, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { Container } from 'typedi';
import { ConnectedRouter } from 'connected-react-router';
import { AnalyticsProvider } from '@chr/react-analytics';
import { initApp } from '../store/epics/app.actions';
import { AppRoutes } from 'app/Routes';
import { History } from 'store/index';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { useDispatcher } from 'app/hooks/store/use-dispatcher.hook';
import { Loader } from 'shared/components/loading/loading.component';
import { ErrorBoundary } from 'shared/components/error-boundary/error-boundary.component';
import { NotImplemented } from 'shared/components/not-implemented/not-implemented.component';
import { InsuranceValidationModal } from 'shared/components/insurance/insurance-validation-modal.component';
import { TermsValidationModal } from 'shared/components/terms-and-conditions/terms-validation-modal.component';
import { HazmatValidationModal } from 'shared/components/hazmat/hazmat-validation-modal.component';
import { useLoader } from 'app/hooks/use-loader.hook';
import { AnalyticsContainer } from 'features/analytics/components/analytics-v2-container.component';
import { useMarketoInitialization } from 'features/analytics/onboarding/useMarketo';
import { OktaAuth, OktaAuthIdxOptions, toRelativeUrl } from '@okta/okta-auth-js';
import { getOktaConfig } from '@features/okta/config';
import { Security, LoginCallback } from '@okta/okta-react';
import { logOut, setLoggedIn } from '@features/okta/redux/oktaSlice';
import { Route } from 'react-router';
import { OktaPreferencesComponent } from 'hoc/OktaPreferencesComponent';
import { BrazeSdkInitializer } from '@features/braze/initialization';
import { ChrThemeProvider } from '@chr/eds-react';
import { NotificationBadgeProvider } from 'pages/payments/providers/notification-badge-context.provider';
import { Comm100Component } from 'shared/components/comm100/comm-100-component';
import { AuthStatus } from 'features/okta/types';
import { useCookie } from 'app/hooks/use-cookie.hook';
import { Cookies } from './globals/constants';
import { v4 as uuidv4 } from 'uuid';
import { BootStrap } from '@containers/bootstrap';
import useSetCookieOnQueryParam from 'hooks/useSetCookieOnQueryParam';

export const CarrierApp = () => {
 useSetCookieOnQueryParam();
 const dispatch = useDispatch();
 const ldClient = useLDClient();
 const initializeApp = useDispatcher(initApp);
 const { show } = useLoader('lazy');
 const isInitialized = useSelector(state => state.app.initialized);
 const initializeMarketo = useMarketoInitialization();
 const _config = getOktaConfig() as OktaAuthIdxOptions;
 const oktaAuth = new OktaAuth(_config);
 const [visitorId, setVisitorId] = useCookie(Cookies.visitorId);

 const restoreOriginalUri = useCallback(async (_oktaAuth: OktaAuth, originalUri: string) => {
  window.location.replace(toRelativeUrl(originalUri || '/login', window.location.origin));
 }, []);

 const ensureVisitorId = () => {
  if (!visitorId) {
   let id = '';
   // if we get to a point where our supported browsers all have crypto
   // we can remove the uuidv4 dependency
   if (self?.crypto?.randomUUID) {
    id = self.crypto.randomUUID();
   } else {
    id = uuidv4();
   }
   setVisitorId(id);
  }
 };

 async function setAuthState() {
  const isAuthenticated = await oktaAuth.isAuthenticated();
  if (!isAuthenticated) {
   return;
  }
  let session = null;
  let error = null;

  try {
   session = await oktaAuth.fingerprint();
  } catch (e) {
   error = e;
  }

  const authState = {
   status: AuthStatus.ACTIVE,
   isAuthenticated: true,
   isInitialized: true,
   didLogin: true,
   logoutWarningVisible: false,
   carrierUser: null,
   session,
   error,
  };
  dispatch(setLoggedIn(authState));
 }
 useEffect(() => {
  show();
  initializeApp();
  initializeMarketo;
  setAuthState();
  ensureVisitorId();
 }, []);

 const onAuthRequired = () => {
  dispatch(logOut());
 };

 useEffect(() => {
  if (ldClient) {
   // TODO: Kill `typedi`
   Container.set('LD_CLIENT', ldClient);
  }
 }, [ldClient]);

 return (
  <Suspense fallback={<Loader name="main" />}>
   <ErrorBoundary errorType="SITE_LEVEL_ERROR">
    <BootStrap>
     <AnalyticsProvider scriptSrc={appConstants.analyticsUrl}>
      <Security {...{ oktaAuth, restoreOriginalUri, onAuthRequired }}>
       <OktaPreferencesComponent>
        <BrazeSdkInitializer>
         <NotificationBadgeProvider>
          <ChrThemeProvider>
           <ConnectedRouter history={Container.get(History)}>
            <div className="app-component">
             <Route path="/login/callback" component={LoginCallback} />
             <NotImplemented isModal modalName="not-implemented" />
             <HazmatValidationModal />
             <TermsValidationModal />
             <InsuranceValidationModal />
             {isInitialized ? <AppRoutes /> : null}
             <Loader name="main" />
             <AnalyticsContainer />
             <Comm100Component />
            </div>
           </ConnectedRouter>
          </ChrThemeProvider>
         </NotificationBadgeProvider>
        </BrazeSdkInitializer>
       </OktaPreferencesComponent>
      </Security>
     </AnalyticsProvider>
    </BootStrap>
   </ErrorBoundary>
  </Suspense>
 );
};
