import React from 'react';

import { InsuranceValidationComponent } from 'shared/components/insurance/insurance-validation.component';
import { useCarrierDetails } from 'app/hooks/store/use-carrier-details.hook';
import { Page } from 'shared/components/analytics/page.component';

export const RequiresInsurance = ({children}) => {
  const carrier = useCarrierDetails();

  if (!carrier) {
    return null;
  }

  return (
    <React.Fragment>
      {
        (carrier.isInsuranceRequired && carrier.hasExpiredInsurance())
          ? <Page name="INSURANCE"><InsuranceValidationComponent carrier={carrier} pendingCarrier={carrier}/></Page>
          : children
      }
    </React.Fragment>
  );
};
