import { Box } from '@mui/material';
import { AnchorButton, Button } from '@shared/components/elements/button.component';
import { Translation } from '@shared/components/translation/translation.component';
import React from 'react';
import { AppRoute } from '@app/routesEnum';
import { PREFERENCES_SETTING_SECTION } from '@pages/settings/form/notifications-preferences/notifications-preferences.component';

import './notification-center.scss';

interface IProps {}

export const NotificationEmptyState: React.FC<IProps> = () => {
  return (
    <Box className="empty-state-container" sx={{ flex: 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <img className="box-flaps" src="/assets/images/box-flaps-light.png" alt="box-flats" />

        <span className="title">
          <Translation resource="YOU_DONT_HAVE_NOTIFICATIONS" />
        </span>
        <span className="subTitle">
          <Translation resource="IS_THERE_SOMETHING_YOU_WOULD_LIKE_TO_SEE_IN_NOTIFICATIONS" />
        </span>
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '24px' }}>
          <AnchorButton
            href={`${AppRoute.SETTINGS}/#/${PREFERENCES_SETTING_SECTION}`}
            btnPrimary
            resource="REVIEW_NOTIFICAITON_CENTER"
            track
            id="btn-review-notification-settings"
          />
        </Box>
      </Box>
    </Box>
  );
};
