import React from 'react';

import { Translation, TranslationJSX } from 'shared/components/translation/translation.component';
import { useCarrierDetails } from 'app/hooks/store/use-carrier-details.hook';
import { useResources } from 'app/hooks/store/use-resources.hook';
import { useSelector } from 'app/hooks/store/use-selector.hook';

import './hazmat-validation.component.scss';

interface OwnProps {
  suppressHeader?: boolean;
}

type Props = OwnProps;

export const HazmatValidation: React.FC<Props> = ({suppressHeader}) => {
  const pendingCarrier = useSelector(state => state.auth.pending.carrier);
  const currentCarrier = useCarrierDetails();
  const resources = useResources();

  const carrier = pendingCarrier || currentCarrier;

  return (
    Boolean(carrier && resources && Object.keys(resources).length) &&
    <div id="hazmat-validation-page">
      <div id="hazmat-validation">
        {
          !suppressHeader && (carrier.hasExpiredHazmat()
            ? <h3><Translation resource="HAZMAT_CERTIFICATE_EXPIRED"/></h3>
            : <h3><Translation resource="HAZMAT_CERTIFICATE_EXPIRING_SOON"/></h3>)
        }
        <div>
          <div>
            <p>
              {
                carrier.hasExpiredHazmat()
                  ? <Translation resource="OUR_RECORDS_HAZMAT_EXPIRED"/>
                  :
                    carrier.hasExpiringHazmat()
                    && <Translation resource="OUR_RECORDS_INDICATE_THAT_YOUR_HAZMAT_CERTIFICATE_" replacements={[carrier.daysUntilHazmatExpiration]}/>
              }
            </p>
            <p>
              <TranslationJSX
                resource="PLEASE_SUBMIT_YOUR_UPDATED_CERTIFICATE_BY_FAX_TO_3"
                replacements={[<span className="fax-number">312-660-4026</span>]}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
