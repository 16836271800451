import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

// Mapping function to map language values to filenames
const mapLanguageToFilename = (lng) => {
  const mappings = {
    'bg': 'bg-BG',
    'cs': 'cs-CZ',
    'de': 'de-DE',
    'hu': 'hu-HU',
    'it': 'it-IT',
    'ja': 'ja-JP',
    'ko': 'ko-KR',
    'nl': 'nl-NL',
    'pl': 'pl-PL',
    'ro': 'ro-RO',
    'ru': 'ru-RU',
    'sk': 'sk-SK',
    'tr': 'tr-TR',
  };

  // Check if the language has a specific mapping
  if (mappings[lng]) {
    return mappings[lng];
  }

  // Default to just using the language code with .json extension
  return `${lng}`;
};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en-US',
        backend: {
          loadPath: (languages) => {
            console.log(languages);
            return languages.map(lng => `/assets/lang/${mapLanguageToFilename(lng)}.json`);
          }
        },
        interpolation: {
            prefix: '{',
            suffix: '}'
        }
    });

export default i18n;