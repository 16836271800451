import React from 'react';
import classNames from 'classnames';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Translation } from 'shared/components/translation/translation.component';
import { isAuthorized } from 'features/security/firewall';
import { AppRoute } from 'app/routesEnum';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { useStoredUser } from 'app/hooks/store/use-stored-user.hook';
import { useCarrierDetails } from 'app/hooks/store/use-carrier-details.hook';

export interface Props {
  url: AppRoute;
  resourceKey: ResourceKey;
  target?: string;
  onClick?: any;
  ariaHasPopup?: boolean;
  ariaExpanded?: boolean;
  className?: string;
  subUrls?: AppRoute[];
}

export const NavItem: React.FC<Props> = ({ url, subUrls = [], resourceKey, target, onClick, ariaHasPopup, ariaExpanded, className }) => {
  const location = useSelector(state => state.router.location);
  const isACSUser = useSelector(state => state.auth.isACSUser);
  const user = useStoredUser();
  const carrier = useCarrierDetails();

  const isActive = useMemo(() => {
    let routes = [url, ...subUrls];
    return routes.some(item => location.pathname.startsWith(item));
  }, [location, url, subUrls]);

  if (!isAuthorized(user, url, carrier, isACSUser)) {
    return null;
  }

  {
    /* react-router-dom <NavLink /> is broken only calculating active state on initial mount. User <Link /> instead */
  }
  return (
    <Link
      target={target}
      to={url}
      className={classNames({ active: isActive }, 'js-nav-item', className)}
      onClick={onClick}
      aria-haspopup={ariaHasPopup}
      aria-expanded={ariaExpanded}
    >
      <Translation resource={resourceKey} />
      {isActive && (
        <span className="visually-hidden">
          {' '}
          <Translation resource="CURRENT_PAGE" />
        </span>
      )}
    </Link>
  );
};
