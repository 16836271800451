import { Container } from 'typedi';

Container.set({ id: 'apiConfig.userAPI', factory: () => apiConfig.userAPI });
Container.set({ id: 'apiConfig.identityAPI', factory: () => apiConfig.identityAPI });
Container.set({ id: 'apiConfig.interimAPI', factory: () => apiConfig.interimAPI });
Container.set({ id: 'apiConfig.gatewayAPI', factory: () => apiConfig.gatewayAPI });
Container.set({ id: 'apiConfig.myLoadsAPI', factory: () => apiConfig.myLoadsAPI });
Container.set({ id: 'apiConfig.settingsAPI', factory: () => apiConfig.settingsAPI });
Container.set({ id: 'apiConfig.documentsAPI', factory: () => apiConfig.documentsAPI });
Container.set({ id: 'apiConfig.availableLoadsAPI', factory: () => apiConfig.availableLoadsAPI });
Container.set({ id: 'apiConfig.shipmentsAPI', factory: () => apiConfig.shipmentsAPI });
Container.set({ id: 'apiConfig.carrierAPI', factory: () => apiConfig.carrierAPI });
Container.set({ id: 'apiConfig.geolocationAPI', factory: () => apiConfig.geolocationAPI });
Container.set({ id: 'apiConfig.websiteAPI', factory: () => apiConfig.websiteAPI });
Container.set({ id: 'apiConfig.notificationsAPI', factory: () => apiConfig.notificationsAPI });
Container.set({ id: 'apiConfig.financialsAPI', factory: () => apiConfig.financialsAPI });
Container.set({ id: 'apiConfig.ncFinancialsAPI', factory: () => apiConfig.ncFinancialsAPI });
Container.set({ id: 'apiConfig.globalShipmentsAPI', factory: () => apiConfig.globalShipmentsAPI });
Container.set({ id: 'apiConfig.geographyAPI', factory: () => apiConfig.geographyAPI });
Container.set({ id: 'apiConfig.onboardingAPI', factory: () => apiConfig.onboardingAPI });
Container.set({ id: 'apiConfig.promotionsAPI', factory: () => apiConfig.promotionsAPI });
Container.set({ id: 'apiConfig.preferencesAPI', factory: () => apiConfig.preferencesAPI });
Container.set({ id: 'appConstants.appName', factory: () => appConstants.appName });
Container.set({ id: 'appConstants.buildNumber', factory: () => appConstants.buildNumber });
Container.set({ id: 'appConstants.buildEnvironment', factory: () => appConstants.buildEnvironment });
Container.set({ id: 'appConstants.analyticsUrl', factory: () => appConstants.analyticsUrl });
Container.set({ id: 'appConstants.bingMapsKey', factory: () => appConstants.bingMapsKey });
Container.set({ id: 'appConstants.branchApiKey', factory: () => appConstants.branchApiKey });
Container.set({ id: 'appConstants.tmcReportsURL', factory: () => appConstants.tmcReportsURL });
Container.set({ id: 'appConstants.cdmsPageUrl', factory: () => appConstants.cdmsPageUrl });
Container.set({ id: 'appConstants.internalAPIKey', factory: () => appConstants.internalAPIKey });
Container.set({ id: 'appConstants.legacyURL', factory: () => appConstants.legacyURL });
Container.set({ id: 'appConstants.launchDarklyClientId', factory: () => appConstants.launchDarklyClientId });
Container.set({ id: 'appConstants.realTimeEventsUrl', factory: () => appConstants.realTimeEventsUrl });
Container.set({ id: 'appConstants.marketoBaseUrl', factory: () => appConstants.marketoBaseUrl });
Container.set({ id: 'appConstants.marketoId', factory: () => appConstants.marketoId });
Container.set({ id: 'appConstants.marketoRegistrationFormId', factory: () => appConstants.marketoRegistrationFormId });
Container.set({ id: 'appConstants.marketoSignupFormId', factory: () => appConstants.marketoSignupFormId });
Container.set({ id: 'appConstants.marketoExistingTCodeFormId', factory: () => appConstants.marketoExistingTCodeFormId });
Container.set({ id: 'appConstants.cloudFlareSiteKey', factory: () => appConstants.cloudFlareSiteKey });
Container.set({ id: 'remoteAuthToken', factory: () => remoteAuthToken });
Container.set({ id: 'internalAPI', factory: () => window[appConstants.internalAPIKey] || {} });
Container.set({ id: 'parentInternalAPI', factory: () => window.opener && window.opener[appConstants.internalAPIKey] || {} });
Container.set('toasts.defaultTimeout', 7000);

Container.set('parentSessionStorage', null);
