import React from 'react';
import classNames from 'classnames';
import { FunctionComponent, useCallback, useRef, useState } from 'react';

import { AppRoute } from 'app/routesEnum';
import { NavItem } from '../nav-items/nav-item.component';
import { Translation } from 'shared/components/translation/translation.component';
import { useOnOutsideClick } from 'app/hooks/use-on-outside-click.hook';
import { Grant } from 'features/security/grants';
import { useCloseMenuOnBlur } from './hooks/use-close-menu-on-blur.hook';
import { useMarkActiveOnNavigation } from './hooks/use-mark-active-on-navigation.hook';
import { useStoredUser } from 'app/hooks/store/use-stored-user.hook';
import { useRouterLocation } from 'app/hooks/store/use-router-location.hook';
import { useResource } from 'app/hooks/store/use-resource.hook';
import { NotificationBadge } from 'pages/payments/payment-tab/notification-badge.component';
import { useNotificationBadgeCounts } from 'pages/payments/hooks/use-notification-badge-counts';
import { PAYMENTS_MENU } from 'pages/payments/constants';

import './sub-menu.component.scss';

export interface Props {
  className?: string;
  grant?: Grant;
  menuTriggerLabel: ResourceKey;
  triggerRoutes?: boolean;
  triggerLink?: AppRoute;
  ariaControls: string;
  subMenuItems: any[];
  children?: React.ReactNode;
}

export const SubMenu: FunctionComponent<Props> = ({ grant, subMenuItems, menuTriggerLabel, ariaControls, triggerLink, ...props }) => {
  const user = useStoredUser();
  const location = useRouterLocation();
  const SUB_MENU = useResource('SUB_MENU');

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const { waitingOnDocuments } = useNotificationBadgeCounts();

  const toggleSubMenu = (event) => {
    event.preventDefault();
    setIsOpen(state => !state);
  };

  const myRef = useRef(null);
  const closeSubMenu = () => setIsOpen(false);

  useMarkActiveOnNavigation(subMenuItems, location, setIsActive, closeSubMenu);
  useOnOutsideClick(myRef, closeSubMenu);
  const onBlur = useCloseMenuOnBlur(myRef, closeSubMenu);

  if (grant && !grant(user)) {
    return null;
  }

  return (
    <li className={props.className} ref={myRef} onBlur={onBlur}>
      {props.triggerRoutes
        ? <div className={classNames('sub-menu-trigger', isActive ? 'active' : null)}>
          <NavItem url={triggerLink} aria-haspopup="true" aria-expanded={isOpen} resourceKey={menuTriggerLabel} />
          <button
            title={SUB_MENU}
            className={classNames('glyphicon', isOpen ? 'glyphicon-chevron-up' : 'glyphicon-chevron-down')}
            aria-expanded={isOpen}
            aria-controls={ariaControls}
            onClick={toggleSubMenu}
          >
            {isOpen
              ? <span className="visually-hidden"><Translation resource="CLOSE_SUB_MENU_FOR" /> <Translation resource={menuTriggerLabel} /></span>
              : <span className="visually-hidden"><Translation resource="SHOW_SUB_MENU_FOR" /> <Translation resource={menuTriggerLabel} /></span>
            }
          </button>
        </div>
        : <a
          href=""
          className={classNames('sub-menu-trigger', isActive ? 'active' : null)}
          aria-haspopup="true"
          aria-controls={ariaControls}
          aria-expanded={isOpen}
          onClick={toggleSubMenu}
        >
          <Translation resource={menuTriggerLabel} />
          {menuTriggerLabel === PAYMENTS_MENU && <NotificationBadge count={waitingOnDocuments ?? 0} />}
          <span className={classNames('glyphicon', isOpen ? 'glyphicon-menu-up' : 'glyphicon-menu-down')} />
        </a>
      }
      <ul id={ariaControls} className={classNames('sub-menu', { 'open': isOpen })}>
        {subMenuItems.map(item =>
          <li id={item.id ? item.id : ''} key={item.pageName + item.pageUrl}><NavItem resourceKey={item.pageName} url={item.pageUrl} /></li>
        )}
      </ul>
    </li>
  );
};
